<template>
  <v-hover v-slot="{ hover }">    
      <v-card 
        :elevation="hover ? 40 : 2"    
        class="mx-auto ma-12 elevation-10" width="400" shaped>
        <v-toolbar flat height=40px>
        </v-toolbar>

        <tr>
          <td  bgcolor="black" width="8px">                        
          </td>
          <td  bgcolor="white" width="20px">                        
          </td>

          <td cols="4" sm="4" md="4">
            <v-card flat class="black--text" >              
              <h1>사용 권한 없음</h1>
            </v-card >
          </td>          
        </tr>

        <v-toolbar flat height=5px>
        </v-toolbar>
        <v-card-text align="center">            
          <v-img   
            width="80px"     
            src="../assets/warning.png"
            class="mt-3 mb-10"
          ></v-img>
          <div class="mb-3">이 작업을 수행하기 위해서는 권한이 필요합니다.</div>      
          <div class="mb-3">관리자에게 문의하여 주십시요.</div>
          <div class="mb-3"><h4>{{ProgramPermissionInfo.ReqProgram}}</h4></div>
        </v-card-text>    
        <v-card flat align="center">          
          <v-btn
            class="mt-5 mb-7" 
            width="200px"
            x-large 
            outlined        
            color="blue"        
            @click="BackPage()"
            ><v-icon>arrow_back_ios</v-icon>Back</v-btn
          >
        </v-card>
      </v-card>    
  </v-hover>
</template>

          

<script>  
  import { mapState } from "vuex";
  import router from "../router/index";
  
  export default {
    data() {
      return {        
      };
    },    
    components: {          
    },

    computed: {
    ...mapState(["ProgramPermissionInfo"]),
    
  },
    
    
    methods: {    
      BackPage() {        
        router.push({ name: this.$store.state.ProgramPermissionInfo.back });              
      },
    },
  }
</script>
